<template>
  <div>
    <the-app-bar />

    <div class="font-montserrat top">
      <div class="row py-24 px-12 oonpay-bg">
        <div class="col-md-12">
          <h4 class="font-semibold  text-center text-oonpaytext text-3xl">
            {{ response.fullName }}
          </h4>
        </div>
      </div>
      <div class="modal fade" id="purchaseMorePackageStripe" tabindex="-1"
        aria-labelledby="purchaseMorePackageModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="purchaseMorePackageModalLabel">Unused Pack</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              You already have unused pack. Do you want to buy another pack?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="generatePaymentIntent">Ok</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="purchaseMorePackage" tabindex="-1" aria-labelledby="purchaseMorePackageModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="purchaseMorePackageModalLabel">Unused Pack</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              You already have unused pack. Do you want to buy another pack?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="canunusedpackagebepurchase">Ok</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="row py-5 justify-content-center">
        <fade-loader :loading="loading" :color="color"></fade-loader>
      </div>


      <!-- {{response.item1 && response.item1[0] }} -->
      <div v-else class="container-fluid  mt-5 p-5">
        <div class="row">
          <div class="container">
            <div class="row">
              <div class="col-md-6" v-if="response">
                <img :src="
                    `${ImagebaseUrl}/oonpayfoodhub/${response.imageUrl}`
                  " alt="" />
                <div class="row mt-2">
                  <div v-for="photo in response.photos" :key="photo.index" class="col-md-4">
                    <img :src="photo" class="other-img " alt="" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <h4>{{ response.fullName }}</h4>
                <p>
                  {{ response.description }}
                </p>
                <h5>
                  Available Stock:
                  {{ response.availableStock }}
                </h5>
                <h5>
                  Package Unit Cost: {{ currency }}
                  {{ response.cost.toFixed(2) }}
                </h5>
                <h6 class="text-lg">
                  {{ response.merchantStreetAddress }}
                </h6>
                <h6 class="text-lg">
                  {{ Math.round(response.distanceAway / 1000, 2) }} KM Away
                </h6>

                <div class="row my-3">
                  <div class="col-sm-3 ">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button type="button" class="btn btn-outline-secondary btn-number" :disabled="Isdisabled"
                          data-type="minus" @click.prevent="minus">
                          <span class="fa fa-minus"></span>
                        </button>
                      </span>
                      <input type="text" name="quant[1]" class="form-control input-number" v-model="quantity" min="1"
                        max="10" />
                      <span class="input-group-append">
                        <button type="button" :disabled="disabledPlus" class="btn btn-outline-secondary btn-number"
                          data-type="plus" @click.prevent="plus">
                          <span class="fa fa-plus"></span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                {{ countryISOCode }}
                <div class="row">
                  <div class="col-md-5">
                    <button class="btn btn-outline-danger">
                      Expires in
                      {{ response.expirationQuantity }}
                      {{ response.expirationQuantityUnit }} After Purchase
                    </button>
                  </div>
                  <div class="col-md-6" v-if="countryISOCode == 'GHS' || countryISOCode == 'NGN'">
                    <div>
                      <button @click="canpackagebepurchase" type="button" class="btn btn-block btn-success">
                        Make Payment
                      </button>
                    </div>
                    <div>
                      <paystack style="opacity: 0;" class="btn btn-danger submit  btn-block" :amount="total * 100"
                        :email="email" :paystackkey="GH_PUBLIC_KEY" :reference="reference" :callback="processPayment"
                        :close="close" currency="GHS" :channels="channels" v-if="countryISOCode == 'GHS'"
                        :metadata="metadata" :disabled="disabled">
                        Proceed to checkout
                      </paystack>

                      <paystack style="opacity: 0;" class="btn btn-danger logout btn-block" :amount="total * 100"
                        :email="email" :paystackkey="NG_PUBLIC_KEY" :reference="reference" :callback="processPayment"
                        :close="close" currency="NGN" :channels="channels" :metadata="metadata"
                        v-if="countryISOCode == 'NGN'">
                        Proceed to checkout
                      </paystack>
                    </div>
                  </div>
                  <div v-else class="col-md-6">
                    <button type="button" class="btn btn-block btn-info" @click.prevent="generatePaymentIntent">
                      Make Payment
                    </button>
                  </div>
                  <button style="opacity: 0;" type="button" class="btn openPaystack btn-block btn-success"
                    data-toggle="modal" data-target="#makePaymentModal">
                    Make Payment
                  </button>

                  <!-- Stripe payment -->
                  <div class="modal fade" id="makePaymentModal" tabindex="-1" role="dialog"
                    aria-labelledby="makePaymentModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLongTitle">
                            Payment information
                          </h5>

                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body ">
                          <div v-if="elementsOptions.clientSecret">
                            <stripe-element-payment ref="paymentRef" :pk="pk" :elements-options="elementsOptions"
                              :confirm-params="confirmParams" />
                            <button class="btn btn-primary mt-3 btn-block" @click.prevent="pay">
                              Pay Now
                            </button>
                          </div>
                        </div>
                        <div class="modal-footer"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
import paystack from "vue-paystack";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import $ from "jquery";
import { baseUrl, config, ImagebaseUrl } from "../../../config";
import { PackPurchaseJson as originalPackPurchaseJson } from "../../../helper";


const NG_PUBLIC_KEY = config.NG_PUBLIC_KEY;
const PK = config.PK;
const GH_PUBLIC_KEY = config.GH_PUBLIC_KEY;
const webKey = config.webKey;




export default {
  components: { TheAppBar, Footer, paystack, FadeLoader, StripeElementPayment },

  data() {
    return {
      ImagebaseUrl,
      response: {},
      showpaystackbtn: false,
      makepayment: true,

      color: "#0b4369",

      pk: PK,
      elementsOptions: {
        appearance: {}, // appearance options
        clientSecret: ""
      },
      currency: JSON.parse(localStorage.getItem("account_info")).countryCurrency,
      getInvoiceDetails: {},
      NG_PUBLIC_KEY: NG_PUBLIC_KEY,
      GH_PUBLIC_KEY: GH_PUBLIC_KEY,
      metadata: {
        benefactorGUID: localStorage.getItem("clientguid"),
        webKey: webKey,
        paymentTypeId: 5,
        foodHubPackPurchaseJson: JSON.stringify(originalPackPurchaseJson),
        clientGUID: localStorage.getItem("clientguid")
      },
      IsIgnoreExistingUnusedPackageRule:false,
      disabled: false,
      message: "",
      quantity: 1,
      full_name: null,
      email: JSON.parse(localStorage.getItem("account_info")).email,
      countryISOCode: "US",
      confirmParams: {
        return_url: `${baseUrl}/view-purchased-packs` // success url
      },
      paymentReceiptEmail: "",
      loading: false,
      channels: ["card", "bank", "mobile_money", "bank_transfer"]
    };
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    total() {
      localStorage.setItem("PurchaseQuantity", this.quantity);
      return this.response.cost * this.quantity;
    },

    Isdisabled() {
      return this.quantity <= 1;
    },

    disabledPlus() {
      return this.quantity == this.response.availableStock;
    }
  },

  mounted() {
    this.countryISOCode = JSON.parse(
      localStorage.getItem("account_info")
    ).countryISOCode;
    localStorage.setItem("activeTab", localStorage.getItem("BeneficiaryTypeId"))

    const payload = {
      beneficiaryCountryId: localStorage.getItem("countryId"),
      latitude: localStorage.getItem("latitude"),
      longitude: localStorage.getItem("longitude"),
      packageCountryId: localStorage.getItem("countryId"),
      clientCountryISO: JSON.parse(localStorage.getItem("account_info"))
        .countryISOCode,
      packageSubCategoryId: localStorage.getItem("packageSubCategoryId"),
      packageGUID: localStorage.getItem("packageGUID")
    };
    this.loading = true;
    this.$store
      .dispatch("foodhub/SearchPackDetail", payload)
      .then((res) => {
        if (res.status == 200) {
          this.response = res.data.item1;
          this.loading = false;
        }
      })
      .catch((err) => {
        this.handleError(err)
      });
  },

  methods: {
    plus() {
      this.quantity++;
    },

    minus() {
      this.quantity--;
    },

    handleError(err) {
      if (localStorage.getItem("BeneficiaryTypeId") == null) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Beneficiary Type was not provided"
        });
      } else if (localStorage.getItem("countryId") == null) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Beneficiary Country was not provided"
        });
      } else {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: err.response.data
        });
      }
    },

    canunusedpackagebepurchase(){
      const payload = {
        packageGUID: this.response.guid,
        benefactorGUID: localStorage.getItem("clientguid"),
        beneficiaryCountryId: localStorage.getItem("countryId"),
        packageQuantity: this.quantity,
        beneficiarytypeid:
          localStorage.getItem("BeneficiaryTypeId"),
        beneficiaryPhoneNumber:
          localStorage.getItem("BeneficiaryTypeId") == 3
            ? JSON.parse(localStorage.getItem("account_info")).phoneNumber
            : localStorage.getItem("BeneficiaryPhoneNumber"),
        ignoreExistingUnusedPackageRule: true
      };

      const modifiedPackPurchaseJson = {
        ...originalPackPurchaseJson,
        IgnoreExistingUnusedPackageRule: true,
        PurchaseQuantity: this.quantity
      };

      this.$store
        .dispatch("default/canpackagebepurchase", payload)
        .then((response) => {
          if (response.status == 200) {
            $("#purchaseMorePackage").modal("hide");
            if (this.countryISOCode == "GHS") {
              this.metadata.FoodHubPackPurchaseJson = JSON.stringify(modifiedPackPurchaseJson)
              document.querySelector(".submit").click();
              this.IsIgnoreExistingUnusedPackageRule = true
            } else {
              document.querySelector(".logout").click();
            }
          }
        })
        .catch((err) => {
          if (err.response.data == "purchaseMorePackage") {
            $("#purchaseMorePackage").modal("show");
            

            this.metadata.FoodHubPackPurchaseJson = JSON.stringify(modifiedPackPurchaseJson)
           //console.log(err.response.data)
          } else {
            this.handleError(err)
          }
        });

    },

    canpackagebepurchase() {
      const payload = {
        packageGUID: this.response.guid,
        benefactorGUID: localStorage.getItem("clientguid"),
        beneficiaryCountryId: localStorage.getItem("countryId"),
        packageQuantity: this.quantity,
        beneficiarytypeid:
          localStorage.getItem("BeneficiaryTypeId"),
        beneficiaryPhoneNumber:
          localStorage.getItem("BeneficiaryTypeId") == 3
            ? JSON.parse(localStorage.getItem("account_info")).phoneNumber
            : localStorage.getItem("BeneficiaryPhoneNumber"),
        ignoreExistingUnusedPackageRule: "false"
      };

      const modifiedPackPurchaseJson = {
        ...originalPackPurchaseJson,
        PurchaseQuantity: this.quantity
      };

      this.$store
        .dispatch("default/canpackagebepurchase", payload)
        .then((response) => {
          if (response.status == 200) {
            $("#purchaseMorePackage").modal("hide");
            if (this.countryISOCode == "GHS") {
              this.metadata.FoodHubPackPurchaseJson = JSON.stringify(modifiedPackPurchaseJson)
              document.querySelector(".submit").click();
            } else {
              document.querySelector(".logout").click();
            }
          }
        })
        .catch((err) => {
         // console.log(err)
          if (err.response.data == "purchaseMorePackage") {
            $("#purchaseMorePackage").modal("show");
           // console.log(err.response.data)
          } else {
            this.handleError(err)
          }
        });
    },

    async generatePaymentIntent() {
      let payload = {};


      const modifiedPackPurchaseJson = {
        ...originalPackPurchaseJson,
        IgnoreExistingUnusedPackageRule: true,
        PurchaseQuantity: this.quantity
      };

      const modifiedPackPurchase = {
        ...originalPackPurchaseJson,
        PurchaseQuantity: this.quantity
      };

      if (this.IsIgnoreExistingUnusedPackageRule) {
        payload = {
          paymentTypeId: 2,
          FoodHubPackPurchaseJson: JSON.stringify(modifiedPackPurchaseJson)
        };
      } else {
        payload = {
          paymentTypeId: 2,
          FoodHubPackPurchaseJson: JSON.stringify(modifiedPackPurchase)
        };
      }

      try {
        const res = await this.$store.dispatch("invoice/getSecurite", payload);
        if (res.data) {
          this.elementsOptions.clientSecret = res.data;
          $("#purchaseMorePackageStripe").modal("hide");
          $("#makePaymentModal").modal("show");
        }
      } catch (err) {
        if (err.response.data == "purchaseMorePackage") {
          this.IsIgnoreExistingUnusedPackageRule = true
          $("#purchaseMorePackageStripe").modal("show");
          //console.log(err.response.data)
        } else {
          this.handleError(err)
        }
        // this.$swal({
        //   icon: "error",
        //   title: "Oops...",
        //   text: error.response.data
        // });
        //console.error('Error generating payment intent:', error);
        // You may want to add some user notification or error handling here
      }
    },

    pay() {
      this.$refs.paymentRef.submit();
    },

    // Paystack
    processPayment: () => {
      location = "/view-purchased-packs";
    },
    close: () => {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: "You closed checkout page"
      });
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

.other-img {
  width: 100%; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

.bg-warning {
  background: #e87c34 !important;
  color: aliceblue;
  font-weight: 500;
}
</style>
